.about {
    padding-top: 150px;

    .about-pfp {
        height: 300px;
        width: 100%;
        object-fit: contain;
    }

    .about-col__left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 20px;

        .about-name {
            font-size: 2.5rem;
            font-weight: bold;
            margin-bottom: 0;
        }

        .about-title {
            font-size: 1.5rem;
            margin-top: 0;
        }

        .icon-list {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .icon {
                list-style-type: none;
                margin-right: 30px;

                &.linkedin-icon,
                &.email-icon {
                    img {
                        filter: invert(1);
                    }
                }

                img {
                    height: 40px;
                    width: 40px;
                    object-fit: contain;
                }
            }
        }
    }

    .about-col__right {
        h2 {
            font-size: 2.5rem;
            margin-bottom: 0;
        }

        .about-text {
            font-size: 1.5rem;
        }

        h3 {
            font-size: 2rem;
            margin-bottom: 0;
        }

        .about-interests {
            font-size: 1.3rem;
        }

        .about-skills {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            font-size: 1.3rem;

            li {
                width: 50%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .about {
        margin-right: 20px;
        margin-left: 10px;
        .about-col__right {
            .about-text {
                font-size: 1rem;
            }
        }
    }
}