/* Import Bootstrap CSS */
@import '../node_modules/bootstrap/dist/css/bootstrap-grid.min.css';

/* Import Header Font */
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800&display=swap');

body {
    font-family: 'Heebo', sans-serif;
    background-color: black;
    color: white;
}