.navigation {
    height: 100%;

    .menu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        padding: 0;
        margin: 0;

        .navigation-item {
            list-style: none;
            margin-right: 50px;

            .header__link {
                font-size: 18px;
                text-transform: uppercase;
                text-decoration: none;
                color: white;
                transition: 500ms all;

                &:hover {
                    color: lightgreen;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .burger-menu {
        display: none;
        position: absolute;
        top: 10px;
        right: 10px;
        
        img {
            height: 50px;
            width: 50px;
            transform: rotate(180deg);
            filter: invert(1);
        }
    }
}

@media only screen and (max-width: 768px) {
    .navigation {
        .burger-menu {
            z-index: 5;
            display: block;

            .open {display: block;}
            .close {display: none;}

            &.mobile-active {
                .open {display: none;}
                .close {display: block;}
            }
        }
        .menu {
            transform: translateX(100%);
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            z-index: 4;
            background-color: black;
            padding-top: 100px;
            padding-left: 20px;
            transition: 500ms all;
            
            &.mobile-active {
                transform: translateX(0%);
            }
            
            .navigation-item {
                margin-bottom: 30px;

                .header__link {
                    font-size: 2.3rem;
                    font-weight: bold;
                }
            }
        }
    }
}