.main-banner {
    background-color: black;
    height: 100vh;
    overflow: hidden;

    .stars, .twinkling {
        position:absolute;
        display:block;
        top:0; bottom:0;
        left:0; right:0;
        width:100%; height:100%;
    }
    
    .stars {
        z-index: 0;
        background: #000 url('https://i.imgur.com/YKY28eT.png') repeat top center;
    }
    
    .twinkling{
        z-index: 1;
        background:transparent url('https://i.imgur.com/XYMF4ca.png') repeat top center;
        animation: move-twink-back 200s linear infinite;
    }
    
    @keyframes move-twink-back {
        from {background-position:0 0;}
        to {background-position:-10000px 5000px;}
    }
    
    @keyframes move-clouds-back {
        from {background-position:0 0;}
        to {background-position:10000px 0;}
    }

    .main-banner__content {
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 5;
        text-align: center;

        .main-banner__title {
            font-size: 60px;

            &--highlight {
                color: lightgreen;
            }
        }

        .main-banner__text {
            font-size: 30px;
            margin-bottom: 60px;
        }

        .main-banner__button {
            background-color: transparent;
            border: 2px solid lightgreen;
            color: white;
            padding: 15px 35px;
            text-decoration: none;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 18px;
            transition: 500ms all;

            &:hover {
                background-color: lightgreen;
                color: black;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .main-banner {
        .main-banner__content {
            width: 65vw;
            
            .main-banner__title {
                font-size: 40px;
            }

            .main-banner__text {
                font-size: 20px;
            }

            .main-banner__button {
                padding: 10px 25px;
                font-size: 14px;
            }
        }
    }
}